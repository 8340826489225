export const environment = {
    pageName: 'Universal Air CMS',
    appFlavor: 'universal',
    production: true,
    appEnv: 'prod',
    googleMapsKey: 'AIzaSyAy7rxvjp2qdbbJGgBB-EUzbWQLwnT_OcM',
    apiConfig: {
        projectEndpoint: 'https://app-api.flyuniversalair.com',
        apiKey: '41270dbd-9113-4d35-b38a-dedd4be386ac',
    },
    firebase: {
        apiKey: 'AIzaSyAy7rxvjp2qdbbJGgBB-EUzbWQLwnT_OcM',
        authDomain: 'universal-air-app.firebaseapp.com',
        databaseURL:
            'https://universal-air-app.europe-west1.firebasedatabase.app',
        projectId: 'universal-air-app',
        storageBucket: 'universal-air-app-prod',
        messagingSenderId: '745520110455',
        appId: '1:745520110455:web:3041a3a4098fcbc7c6df72',
        measurementId: 'G-3HQJ83D06J',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 5.605186,
        longitude: -0.166786,
        ISO3166_1_Alpha_2: 'US',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
        { key: 'de', title: 'LANGUAGES.DEUTSCH', label: 'FORMS.DE_LANG' },
        { key: 'hu', title: 'LANGUAGES.HUNGRIAN', label: 'FORMS.HU_LANG' },
        { key: 'it', title: 'LANGUAGES.ITALIANO', label: 'FORMS.IT_LANG' },
        { key: 'mt', title: 'LANGUAGES.MALTI', label: 'FORMS.MT_LANG' },
        { key: 'fr', title: 'LANGUAGES.FRENCH', label: 'FORMS.fr_LANG' },
        { key: 'cs', title: 'LANGUAGES.CZECH', label: 'FORMS.cz_LANG' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId: '',
    signInOptions: ['password'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
